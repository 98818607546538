import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E6E6FA', // Lavender
    },
    secondary: {
      main: '#808080', // Grey
    },
  },
});

const HOST_URL = 'http://localhost:5000'; 


const useStyles = makeStyles(theme => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#E6E6FA',
    color: '#808080',
  },
  header: {
    padding: theme.spacing(2),
    backgroundColor: '#E6E6FA',
    textAlign: 'center',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    maxWidth: 400,
    margin: '0 auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  imageContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ImageManipulationApp: React.FC = () => {
  const classes = useStyles();
  const [selectedTool, setSelectedTool] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const tools: any[] = [
    { displayName: 'Prepare for Engraving', endpoint: 'engrave' },
    { displayName: 'Crop to Face', endpoint: 'crop_to_face' },
    { displayName: 'Outline for Cutting', endpoint: 'outline_for_cutting' }
  ]; // replace with your tools

  const handleToolChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectedTool(event.target.value as string);
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files ? event.target.files[0] : null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (!selectedTool || !file) return;

    const selectedToolInfo = tools.find(tool => tool.displayName === selectedTool);
    if (!selectedToolInfo) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      setLoading(true);
      const response = await fetch(`${HOST_URL}/${selectedToolInfo.endpoint}`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      setImageUrl(URL.createObjectURL(blob));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.app}>
      <header className={classes.header}>
        <h1>Zippity Do Dah</h1>
        {/* Add your navbar here */}
      </header>
      <main className={classes.main}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl className={classes.formControl}>
            <InputLabel id="tool-label">Choose a tool:</InputLabel>
            <Select
              labelId="tool-label"
              id="tool-select"
              value={selectedTool || ''}
              onChange={handleToolChange}
            >
              {tools.map((tool, index) => (
                <MenuItem key={index} value={tool.displayName}>
                  {tool.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            component="label"
            className={classes.button}
          >
            Upload Image
            <input type="file" hidden onChange={handleImageUpload} />
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Submit
          </Button>
        </form>
        {loading && <CircularProgress />}
        {imageUrl && (
          <div className={classes.imageContainer}>
            <img src={imageUrl} alt="Result" />
            <Button
              href={imageUrl}
              download
              startIcon={<SaveAlt />}
            >
              Save Image
            </Button>
          </div>
        )}
      </main>
      <footer>
        {/* Add your footer here */}
      </footer>
    </div>
  );
};

export default ImageManipulationApp;
